import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { getProductFromContext } from '../../context/product';
import { ProductStage, ProductTag } from '../../types/common';
import { getStage, hasProductTag, isEligibleForTimer } from '../../utils/product';
import { S2, H5, P2 } from '../Typography';
import CountdownTimer from '../CountdownTimer';
import { isHolidayHideawayProduct } from '../../utils/bfcm';

const ProductCountdownTimerWrapper = styled.div<{
  isLastDay?: boolean;
  backgroundColour?: string;
}>`
  background-color: ${({ backgroundColour }) => backgroundColour};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  border-radius: 12px;
`;

const CountdownTitle = styled(P2)<{ textColour?: string }>`
  color: ${({ textColour }) => textColour};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    font-size: 14px;
  }
`;

const CountdownTimerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin-bottom: 30px;
  }
`;

type ProductPageCountdownProps = {
  currentDate: string;
  totalInventory?: number;
  selectedProduct?: Shopify.Product;
  backgroundColour?: string;
  textColour?: string;
};

const renderCountdownTitle = (
  stage: string,
  currentDate: string,
  isLastDay?: boolean,
  isLimited?: boolean,
): React.ReactNode => {
  const date = utils.getDateLocalCustomFormat(currentDate, 'MMMM D, h:mmA z');

  if (stage === ProductStage.ComingSoon || stage === ProductStage.Draft) {
    return (
      <>
        ⏰ <strong>NEW</strong> Product Drop: <strong>{date}</strong> ⏰
      </>
    );
  }

  if (isLimited) {
    return;
  }

  if (isLastDay) {
    return (
      <>
        🚨 Hurry! Last chance: <strong>{date}</strong> 🚨
      </>
    );
  }
  return (
    <>
      Only Available Until: <strong>{date}</strong>
    </>
  );
};

const renderCountdownTimer = (
  stage: string,
  currentDate: string,
  productTags: string[],
  backgroundColour?: string,
  textColour?: string,
): React.ReactNode => {
  const isLastDay = utils.getDayDiffFrom(currentDate) === 0;
  const isLimited = productTags.includes(ProductTag.Limited);

  return (
    <CountdownTimerWrapper>
      <ProductCountdownTimerWrapper isLastDay={isLastDay} backgroundColour={backgroundColour}>
        <CountdownTitle data-testid="product-countdown-title" textColour={textColour}>
          {renderCountdownTitle(stage, currentDate, isLastDay, isLimited)}
        </CountdownTitle>
        <CountdownTimer date={currentDate} textColour={textColour} />
      </ProductCountdownTimerWrapper>
    </CountdownTimerWrapper>
  );
};

const ProductPageCountdown: React.FC<ProductPageCountdownProps> = ({
  currentDate,
  totalInventory,
  selectedProduct,
  backgroundColour,
  textColour,
}: ProductPageCountdownProps) => {
  const product = selectedProduct || getProductFromContext();
  const stage = getStage(product.tags);

  return (
    <>
      {hasProductTag(product.tags, ProductTag.Limited) &&
        !isHolidayHideawayProduct(product.id) &&
        totalInventory &&
        totalInventory > 0 &&
        stage !== ProductStage.ComingSoon && (
          <ProductCountdownTimerWrapper>
            <S2>Limited Edition!</S2>
            <H5>Only {totalInventory} left!</H5>
          </ProductCountdownTimerWrapper>
        )}
      {currentDate &&
        isEligibleForTimer(product) &&
        renderCountdownTimer(stage, currentDate, product.tags, backgroundColour, textColour)}
    </>
  );
};

export default ProductPageCountdown;

import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import ProductCard from '../ProductCard';
import { CampaignStatus } from '../../types/common';
import { H3Styles, H5 } from '../Typography';

const ProductContents = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border-radius: 12px;
  background: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 9)};
  padding: 20px 24px;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-left: 0px;
    padding: 16px 6px 10px 6px;
  }
`;

const TitleWrapper = styled.div`
  margin-left: 6px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-left: 20px;
  }
`;

const ProductWrapper = styled.div<{ hasJustOneProduct: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  margin-top: 7px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 0px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: ${({ hasJustOneProduct }) => (hasJustOneProduct ? 'block' : 'grid')};
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
`;

const ProductCardWrapper = styled.div`
  max-width: 340px;
`;

const CampaignStatusTitle = styled(H5)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    ${H3Styles}
  }
`;

type CreatorProductsProps = {
  products: Shopify.ProductEdge[];
  campaignStatus: CampaignStatus;
  creatorName: string;
  isCreatorBrandedPage: boolean;
  isPreview: boolean | undefined;
};

const CreatorProducts: React.FC<CreatorProductsProps> = ({
  products,
  campaignStatus,
  creatorName,
  isCreatorBrandedPage,
  isPreview,
}: CreatorProductsProps) => {
  const renderCampaignStatus = (status: CampaignStatus) => {
    if (status === CampaignStatus.Live) {
      return (
        <>
          <CampaignStatusTitle>Live</CampaignStatusTitle>
        </>
      );
    }
    if (status === CampaignStatus.ComingSoon) {
      return (
        <>
          <CampaignStatusTitle>Coming Soon</CampaignStatusTitle>
        </>
      );
    }
    if (status === CampaignStatus.Past) {
      return (
        <>
          <CampaignStatusTitle>Sold Out</CampaignStatusTitle>
        </>
      );
    }
  };

  if (products.length === 0) {
    return null;
  }
  return (
    <ProductContents>
      <TitleWrapper>{renderCampaignStatus(campaignStatus)}</TitleWrapper>
      <ProductWrapper hasJustOneProduct={products.length === 1}>
        {products.map(({ node }) => (
          <ProductCardWrapper>
            <ProductCard
              key={node.id}
              product={node}
              isOnCreatorCollectionPage
              isCreatorBranded={isCreatorBrandedPage || isPreview}
            />
          </ProductCardWrapper>
        ))}
      </ProductWrapper>
    </ProductContents>
  );
};

export default CreatorProducts;

import { useState, useEffect } from 'react';
import { getProductDropSalesQuantity } from '../api/storefront/product';
import { getProductIDFromShopifyGID } from '../utils/product';

export const useFetchSalesQuantityForProductDrop = (
  productDropCollection: Shopify.Collection | null,
  currentProductDropSalesQuantity: number,
) => {
  const [productDropSalesQuantity, setProductDropSalesQuantity] = useState<number | null>(
    currentProductDropSalesQuantity,
  );

  useEffect(() => {
    setProductDropSalesQuantity(currentProductDropSalesQuantity);
  }, [currentProductDropSalesQuantity]);

  useEffect(() => {
    if (!productDropCollection) {
      setProductDropSalesQuantity(null);
      return;
    }

    const fetchData = async () => {
      try {
        const productIdsJoined = productDropCollection.products.edges
          .map((productEdge: Shopify.ProductEdge) => getProductIDFromShopifyGID(productEdge.node.id))
          .join(',');

        const productDropSalesQuantity = await getProductDropSalesQuantity(productIdsJoined)
          .then((response) => response.data)
          .catch(() => null);

        if (productDropSalesQuantity === null) {
          setProductDropSalesQuantity(null);
          return;
        }

        setProductDropSalesQuantity(productDropSalesQuantity.quantity);
      } catch (err: any) {
        setProductDropSalesQuantity(null);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 10_000);

    return () => clearInterval(intervalId);
  }, [productDropCollection]);

  return { productDropSalesQuantity };
};

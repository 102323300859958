import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { NextPage, GetServerSideProps } from 'next';
import Image from 'next/image';
import posthog from 'posthog-js';
import { Button, utils } from '@makeship/core';
import dynamic from 'next/dynamic';
import Head from '../../../components/Head';
import { H5, S2 } from '../../../components/Typography';

import {
  filterProducts,
  getSortedProducts,
  isCreatorMessageEligible,
  parseCreatorCollectionMetafields,
  areAllProductDropMetafieldsFilled,
  fetchLatestProductDropDetails,
  fetchCYCCollectionTitleForStretchGoalUpdates,
} from '../../../utils/collections';

import { getCollectionByHandle } from '../../../api/collections';
import { getTargetURLFromPath } from '../../../api/storefront/product';

import { useFetchSalesQuantityForGT } from '../../../hooks/useFetchInterval';
import { useFetchSalesQuantityForProductDrop } from '../../../hooks/useFetchSalesQuantityForProductDrop';

import { getPropsForPageLoad } from '../../../utils/pageLoad';
import CreatorMessage from '../../../components/pages/CustomCreatorCollection/CreatorMessage';
import StretchGoalsProgressBarGT from '../../../components/pages/CustomCreatorCollection/StretchGoalsProgressBarGT';
import StretchGoalsRewardListGT, {
  Goal,
} from '../../../components/pages/CustomCreatorCollection/StretchGoalsRewardListGT';
import StretchGoalsRewardList from '../../../components/pages/CustomCreatorCollection/StretchGoalsRewardList';
import StretchGoalsProgressBar from '../../../components/pages/CustomCreatorCollection/StretchGoalsProgressBar';
import { useNavTheme } from '../../../context/theme';
import { NavTheme, CampaignStatus, ProductStage } from '../../../types/common';
import { calculateCombinedMOQ, parseMetafields } from '../../../utils/product';
import { trackSubscribeButtonClicked } from '../../../utils/analytics';
import { useStore } from '../../../store';
import CreatorProducts from '../../../components/CreatorProducts';
import CustomCreatorCollectionHeader from '../../../components/pages/CustomCreatorCollection/CustomCreatorHeader';

import CompleteCollectionComponent from '../../../components/pages/products/CompleteCollectionComponent';
import { VariantSelectionError } from '../../../types/errors';
import GTShopMasthead from '../../../components/pages/CustomCreatorCollection/GTShopMasthead';
import { useWindowSize } from '../../../hooks/useWindowSize';
import ProductPageCountdown from '../../../components/ProductPage/ProductPageCountdown';

const LazySubscribeModal = dynamic(() => import('../../../components/Modal/SubscribeModal'), { ssr: false });

const ShopPageWrapper = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.maxContent}px;
  margin: 0 auto;
  padding: 20px 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 24px 64px;
  }
`;

const SearchQuery = styled(H5)`
  margin-left: 12px;
  font-size: 24px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    font-size: 36px;
  }
`;

const MediaEmbed = styled.div`
  width: 100%;
  max-width: 100%;
  margin-bottom 20px;

  border-radius: 0 12px 12px 12px;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  background-color: ${({ theme }) => theme.colors.neutral7};

  iframe,
  img,
  figure,
  video {
    border-radius: inherit;
    display: block;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    aspect-ratio: 4 / 3;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-bottom: 30px;
  }
`;

const SubscribeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-top: 0px;
  }
`;

const SubscribeContainer = styled.div<{ isCreatorBranded?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px 12px;
  max-width: 718px;
  border-radius: 12px;
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 90)};
  ${({ isCreatorBranded }) => isCreatorBranded && `border: 1px solid var(--opacity-15, rgba(20, 20, 20, 0.15));`}
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 20px 24px;
  }
`;

const SubscribeButton = styled(Button.Primary)<{ isCreatorBranded?: boolean }>`
  width: 100%;
  padding: 14px 16px;
  margin-top: 16px;
  margin-bottom: 26px;
  max-width: 336px;
  ${({ isCreatorBranded, theme }) =>
    isCreatorBranded &&
    `
      background: ${theme.colors.neutral7};
      &:hover {
        background: ${theme.colors.neutral6};
      }
  `}
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 14px 24px;
  }
`;

const SubscribeText = styled(S2)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    font-size: 20px;
  }
`;

const GTShopMastheadWrapper = styled.div`
  margin: 20px 0 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-top: 20px;
  }
`;

const CreatorProductsWrapper = styled.div<{ isCreatorBranded: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const CreatorProductsDisplayWrapper = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-bottom: 30px;
  }
`;

const CompleteCollectionAndMessageAndStretchGoalsWrapper = styled.div<{
  isCreatorMessageEligible: boolean;
  isCompleteCollectionEligible: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    justify-content: ${({ isCreatorMessageEligible }) => (isCreatorMessageEligible ? `space-between` : `center`)};
    flex-direction: row;
    gap: clamp(20px, 5%, 90px);
    align-items: start;
    margin-bottom: ${({ isCompleteCollectionEligible }) => (isCompleteCollectionEligible ? `30px` : `0px`)};
  }
`;

const CreatorMessageWrapperAndStretchGoalsWrapper = styled.div<{
  isCreatorMessageEligible: boolean;
  shouldShowStretchGoals: boolean;
  hasLiveProducts: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;

  @media screen and (min-width: 600px) {
    align-items: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: column;
    align-items: start;
    max-width: ${({ shouldShowStretchGoals, hasLiveProducts }) =>
      shouldShowStretchGoals && hasLiveProducts ? `658px` : `719px`};
    gap: 0px;
  }
`;

const CompleteCollectionWrapper = styled.div<{ isCreatorMessageEligible: boolean }>`
  width: 100%;
  order: 1;
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    min-width: 582px;
    max-width: 636px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    ${({ isCreatorMessageEligible }) => (isCreatorMessageEligible ? `width: 100%` : `min-width: 582px`)};
    border-radius: 12px;
    margin-top 0;
    margin-bottom: 0px;
    border: 2px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
    order: 0;
  }
`;

const CreatorMessageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: block;
    max-width: 719px;
  }
`;

const MobileCreatorMessageWrapper = styled.div`
  order: 2;
  max-width: 719px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`;

const StretchGoalsWrapperGT = styled.div`
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 90)};
  border-radius: 12px;
  margin-bottom: 12px;
  max-width: 446px;
  min-width: 328px;
  padding: 30px 8px 18px 8px;
  margin-bottom: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin: 0;
  }
`;

const StretchGoalsWrapper = styled.div`
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 90)};
  border-radius: 12px;
  width: 100%;
  max-width: 450px;
  min-width: 328px;
  padding: 30px 8px 18px 8px;
  margin-bottom: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin: 0;
  }
`;

const CreatorSocialsWrapper = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  padding: 6px;
  margin-right: 16px;
  border-radius: 12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 38px;
  }
`;

const SocialLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const SocialLink = styled.a`
  width: 26px;
  height: 26x;
  border-radius: 22px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 42px;
    height: 42px;
  }
`;

type Response = {
  title: string;
  shouldShowStretchGoals: boolean;
  milestones: number[];
  goals: Goal[];
};

const collectionGoalsMap: Record<string, Response> = {
  'gid://shopify/Collection/298420043932': {
    title: 'Gorilla Tag Birthday Rewards 🎉',
    shouldShowStretchGoals: true,
    milestones: [200, 10000, 30000, 50000],
    goals: [
      {
        amount: 200,
        title: 'In-game Banana Party Hat <b>AND Tagged Monke Plushie!</b> 🎮 (Codes will be sent March 7th)',
        emoji: '',
        image: '',
      },
      {
        amount: 10000,
        title: 'Holographic Sticker',
        emoji: '✨',
        image: '/assets/stretch-goals/gt-reward-1.png',
      },
      {
        amount: 30000,
        title: 'Holographic Trading Card',
        emoji: '🎴',
        image: '/assets/stretch-goals/gt-reward-2.png',
        additionalText: ' (5 different cards, 1 per purchase)',
      },
      {
        amount: 50000,
        title: 'Banana Hat Plushie Accessory*',
        emoji: '🥳',
        image: '/assets/stretch-goals/gt-reward-3.png',
        additionalText: ' (*fits Plushie and Doughboi)',
      },
    ],
  },
  // staging GT collection
  'gid://shopify/Collection/296209449099': {
    title: 'Gorilla Tag Birthday Rewards 🎉',
    shouldShowStretchGoals: true,
    milestones: [200, 10000, 30000, 50000],
    goals: [
      {
        amount: 200,
        title: 'In-game Banana Party Hat <b>AND Tagged Monke Plushie!</b> 🎮 (Codes will be sent March 7th)',
        emoji: '',
        image: '',
      },
      {
        amount: 10000,
        title: 'Holographic Sticker',
        emoji: '✨',
        image: '/assets/stretch-goals/gt-reward-1.png',
      },
      {
        amount: 30000,
        title: 'Holographic Trading Card',
        emoji: '🎴',
        image: '/assets/stretch-goals/gt-reward-2.png',
        additionalText: ' (5 different cards, 1 per purchase)',
      },
      {
        amount: 50000,
        title: 'Banana Hat Plushie Accessory*',
        emoji: '🥳',
        image: '/assets/stretch-goals/gt-reward-3.png',
        additionalText: ' (*fits Plushie and Doughboi)',
      },
    ],
  },
};

export const getCollectionGoals = (collectionId: string): Response => {
  const goals = collectionGoalsMap[collectionId];
  if (!goals) {
    return {
      title: '',
      shouldShowStretchGoals: false,
      milestones: [],
      goals: [],
    };
  }
  return goals;
};

export type CreatorPageProps = {
  creatorCollection: Shopify.Collection;
  collectionHandle: string;
  creatorCollectionMetafields: CreatorCollectionMetafields;
  latestProductDropCollection: Shopify.Collection | null;
  productDropCollectionMetafields: ProductDropCollectionMetafields | null;
  shouldEnableStretchGoals: boolean;
  isPreview?: boolean | undefined;
};

const CreatorPage: NextPage<CreatorPageProps> = ({
  creatorCollection,
  collectionHandle,
  creatorCollectionMetafields,
  latestProductDropCollection,
  productDropCollectionMetafields,
  shouldEnableStretchGoals,
  isPreview,
}: CreatorPageProps) => {
  const [livePageProducts, setLivePageProducts] = useState<Shopify.ProductEdge[]>([]);
  const [pinAddOn, setPinAddOn] = useState<Shopify.Product | null>(null);
  const completeTheCollectionRef = useRef<HTMLDivElement>(null);
  const [selectedVariant, setSelectedVariant] = useState<Shopify.ProductVariant>();
  const [selectedHoodieVariants, setSelectedHoodieVariants] = useState<Shopify.ProductVariant[]>([]);
  const [errors, setErrors] = useState<VariantSelectionError[]>([]);
  const [isMobileViewport, setIsMobileViewport] = useState<boolean>(false);
  const [countdownToDate, setCountdownToDate] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<Shopify.Product | undefined>(undefined);
  const isGorillaTagCollection =
    creatorCollection &&
    collectionGoalsMap[creatorCollection.id] &&
    collectionGoalsMap[creatorCollection.id].title.includes('Gorilla');

  const { handleNavThemeChange, handleBackgroundColourChange } = useNavTheme();
  const size = useWindowSize();
  const theme = useContext(ThemeContext);

  const { id, metafields } = creatorCollection;

  const { title, shouldShowStretchGoals, milestones, goals } = getCollectionGoals(id);

  const {
    navTheme,
    isCreatorBranded,
    creatorMessage,
    creatorAvatarURL,
    creatorLogoURL,
    socialLinks,
    backgroundColour,
    mediaEmbed,
    headerBackgroundURL,
    countdownBackgroundColour,
    countdownTextColour,
    previewNavTheme,
    previewCreatorMessage,
    previewCreatorAvatarURL,
    previewCreatorLogoURL,
    previewMediaEmbed,
    previewBackgroundColour,
    previewSocialLinks,
    previewCountdownBackgroundColour,
    previewCountdownTextColour,
    cycPromoMessage,
  } = creatorCollectionMetafields;

  const socialLinksObject = JSON.parse(isPreview ? previewSocialLinks || '{}' : socialLinks || '{}');

  const hasLiveProducts = livePageProducts.length > 0;
  const { state } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { gtSalesQuantity } = useFetchSalesQuantityForGT(shouldShowStretchGoals, 0);
  const { productDropSalesQuantity } = useFetchSalesQuantityForProductDrop(latestProductDropCollection, 0);
  const productDropMOQ = calculateCombinedMOQ(latestProductDropCollection);

  const shouldDisplayStretchGoals =
    shouldEnableStretchGoals &&
    latestProductDropCollection !== null &&
    productDropCollectionMetafields !== null &&
    areAllProductDropMetafieldsFilled(productDropCollectionMetafields) &&
    productDropSalesQuantity !== null &&
    productDropMOQ !== null;

  const defaultSubheading = `Shop official products from ${creatorCollection.title} exclusively on Makeship`;

  useEffect(() => {
    if (isCreatorBranded || previewNavTheme) {
      if (isPreview) {
        handleNavThemeChange(previewNavTheme as NavTheme);
        handleBackgroundColourChange(previewBackgroundColour);
      }
      if (isCreatorBranded) {
        handleNavThemeChange(navTheme as NavTheme);
        handleBackgroundColourChange(backgroundColour);
      }
    }
    return () => {
      handleNavThemeChange(NavTheme.default);
      handleBackgroundColourChange(undefined);
    };
  }, [
    creatorCollection,
    handleNavThemeChange,
    navTheme,
    isCreatorBranded,
    backgroundColour,
    handleBackgroundColourChange,
    previewNavTheme,
    previewBackgroundColour,
    isPreview,
  ]);

  useEffect(() => {
    const productEdges = creatorCollection.products.edges;
    const liveProducts = productEdges.filter((product) => product.node.tags.includes(ProductStage.Live));

    setLivePageProducts(getSortedProducts(filterProducts(liveProducts)));
    setPinAddOn(
      productEdges.find(
        (product) => product.node.tags.includes('pinaddon-pinproduct') && product.node.tags.includes(ProductStage.Live),
      )?.node || null,
    );
  }, [creatorCollection]);

  useEffect(() => {
    if (!size.width) {
      return;
    }
    // eslint-disable-next-line react/destructuring-assignment
    const isMobileViewport = size.width < theme.breakpoints.desktop;
    setIsMobileViewport(isMobileViewport);
  }, [size]);

  useEffect(() => {
    const productEdges = creatorCollection.products.edges;
    const comingSoonProducts = productEdges.filter((product) => product.node.tags.includes(ProductStage.ComingSoon));
    const sortedComingSoonProducts = getSortedProducts(filterProducts(comingSoonProducts));
    const hasLiveProducts = livePageProducts.length > 0;
    setSelectedProduct(hasLiveProducts ? livePageProducts[0]?.node : sortedComingSoonProducts[0]?.node);
    const metafields = parseMetafields(selectedProduct?.metafields);
    setCountdownToDate((hasLiveProducts ? metafields.enddate : metafields.launch) || '');
  }, [livePageProducts, selectedProduct, creatorCollection]);

  const campaignStatuses = [
    { tag: 'comingsoon', status: CampaignStatus.ComingSoon },
    { tag: 'past', status: CampaignStatus.Past },
  ];

  const filteredProducts = (tag: string) =>
    getSortedProducts(
      filterProducts(creatorCollection.products.edges.filter((product) => product.node.tags.includes(tag))),
    );

  const linksToIconsMap = {
    twitter: '/assets/icons/twitter-black-icon.svg',
    instagram: '/assets/icons/instagram-black-icon.svg',
    facebook: '/assets/icons/facebook-black-icon.svg',
    youtube: '/assets/icons/youtube-black-icon.svg',
    tiktok: '/assets/icons/tiktok-black-icon.svg',
    discord: '/assets/icons/discord-black-icon.svg',
    twitch: '/assets/icons/twitch-black-icon.svg',
    bluesky: '/assets/icons/bluesky-black-icon.svg',
    other: '/assets/icons/other-black-icon.svg',
  };

  const socialLinksButtons = Object.entries(socialLinksObject).map(([key, value]) => {
    const valueString = value as string;
    if (!valueString) return null;
    return (
      <SocialLinkWrapper>
        <SocialLink
          target="_blank"
          rel="noreferrer"
          key={key}
          href={valueString || ''}
          onClick={() =>
            posthog.capture(`custom_creator_page_social_click`, {
              social_platform: key,
              link_href: value,
              creator_page: creatorCollection?.title,
              user: state.user?.customer?.email,
            })
          }
        >
          <Image
            src={linksToIconsMap[key as keyof typeof linksToIconsMap]}
            height={isMobileViewport ? 26 : 42}
            width={isMobileViewport ? 26 : 42}
            alt={`${key} icon`}
          />
        </SocialLink>
      </SocialLinkWrapper>
    );
  });

  return (
    <>
      <Head
        title={`${creatorCollection.title}`}
        url={`/shop/creator/${collectionHandle}`}
        description={creatorCollection?.description || defaultSubheading}
        image={creatorCollection?.image?.transformedSrc}
      />
      {((isCreatorBranded && headerBackgroundURL && creatorLogoURL) || isPreview) && (
        <CustomCreatorCollectionHeader creatorCollection={creatorCollection} isPreview={isPreview} />
      )}
      {isGorillaTagCollection && (isCreatorBranded || isPreview) && hasLiveProducts && (
        <GTShopMastheadWrapper>
          <GTShopMasthead />
        </GTShopMastheadWrapper>
      )}

      <ShopPageWrapper>
        {(!isCreatorBranded || !headerBackgroundURL || !creatorLogoURL) && !isPreview && (
          <div>
            <SearchQuery data-testid="creator-collection-title">{creatorCollection.title}</SearchQuery>
          </div>
        )}
        {(isCreatorBranded || isPreview) && !!selectedProduct && !!countdownToDate && (
          <ProductPageCountdown
            currentDate={countdownToDate}
            selectedProduct={selectedProduct}
            backgroundColour={isPreview ? previewCountdownBackgroundColour : countdownBackgroundColour || ''}
            textColour={isPreview ? previewCountdownTextColour || 'white' : countdownTextColour || 'white'}
          />
        )}
        {hasLiveProducts && (
          <CreatorProductsDisplayWrapper>
            <CreatorProducts
              products={livePageProducts}
              campaignStatus={CampaignStatus.Live}
              creatorName={creatorCollection.title}
              isCreatorBrandedPage={isCreatorBranded ?? false}
              isPreview={isPreview}
            />
          </CreatorProductsDisplayWrapper>
        )}
        <CompleteCollectionAndMessageAndStretchGoalsWrapper
          isCreatorMessageEligible={isCreatorMessageEligible(
            isCreatorBranded,
            creatorMessage,
            hasLiveProducts,
            isPreview,
            previewCreatorMessage,
          )}
          isCompleteCollectionEligible={hasLiveProducts && (livePageProducts.length > 1 || !!pinAddOn)}
        >
          {hasLiveProducts && (livePageProducts.length > 1 || pinAddOn) && (
            <CompleteCollectionWrapper
              isCreatorMessageEligible={isCreatorMessageEligible(
                isCreatorBranded,
                creatorMessage,
                hasLiveProducts,
                isPreview,
                previewCreatorMessage,
              )}
            >
              <CompleteCollectionComponent
                product={undefined}
                pinAddOnProduct={pinAddOn}
                metafields={metafields}
                productsBySameCreator={livePageProducts}
                productsInSameMakeshiftCollection={[]}
                stage={undefined}
                makeshiftCollectionTitle=""
                completeTheCollectionRef={completeTheCollectionRef}
                setSelectedVariant={setSelectedVariant}
                selectedHoodieVariants={selectedHoodieVariants}
                setSelectedHoodieVariants={setSelectedHoodieVariants}
                errors={errors}
                setErrors={setErrors}
                creatorName={creatorCollection.title}
                isCreatorBranded={isCreatorBranded || isPreview}
                isGTReward={shouldShowStretchGoals}
                cycPromoMessage={cycPromoMessage}
                gtStretchGoalsMet={(gtSalesQuantity?.quantity || 0) >= milestones[milestones.length - 1]}
                titleOverride={fetchCYCCollectionTitleForStretchGoalUpdates(
                  shouldEnableStretchGoals,
                  productDropCollectionMetafields,
                  productDropSalesQuantity,
                  creatorCollection.title,
                  pinAddOn,
                )}
              />
            </CompleteCollectionWrapper>
          )}
          <CreatorMessageWrapperAndStretchGoalsWrapper
            isCreatorMessageEligible={isCreatorMessageEligible(
              isCreatorBranded,
              creatorMessage,
              hasLiveProducts,
              isPreview,
              previewCreatorMessage,
            )}
            shouldShowStretchGoals={shouldShowStretchGoals || shouldDisplayStretchGoals}
            hasLiveProducts={hasLiveProducts}
          >
            {shouldShowStretchGoals && gtSalesQuantity && hasLiveProducts && (
              <StretchGoalsWrapperGT>
                <StretchGoalsProgressBarGT totalUnitsSold={gtSalesQuantity?.quantity} milestones={milestones} />
                <StretchGoalsRewardListGT title={title} goals={goals} totalUnitsSold={gtSalesQuantity?.quantity} />
              </StretchGoalsWrapperGT>
            )}
            {shouldDisplayStretchGoals && (
              <StretchGoalsWrapper>
                <StretchGoalsProgressBar
                  latestProductDropCollection={latestProductDropCollection}
                  productDropCollectionMetafields={productDropCollectionMetafields}
                  totalUnitsSold={productDropSalesQuantity}
                  productDropMOQ={productDropMOQ}
                />
                <StretchGoalsRewardList
                  productDropCollectionMetafields={productDropCollectionMetafields}
                  latestProductDropCollection={latestProductDropCollection}
                  totalUnitsSold={productDropSalesQuantity}
                  productDropMOQ={productDropMOQ}
                />
              </StretchGoalsWrapper>
            )}
            {isCreatorMessageEligible(
              isCreatorBranded,
              creatorMessage,
              hasLiveProducts,
              isPreview,
              previewCreatorMessage,
            ) && (
              <CreatorMessageWrapper>
                <CreatorMessage
                  collectionTitle={creatorCollection.title}
                  creatorMessage={isPreview ? previewCreatorMessage || '' : creatorMessage || ''}
                  creatorAvatarURL={isPreview ? previewCreatorAvatarURL || '' : creatorAvatarURL || ''}
                  creatorLogoURL={isPreview ? previewCreatorLogoURL || '' : creatorLogoURL || ''}
                  shouldDisplayStretchGoals={shouldShowStretchGoals || shouldDisplayStretchGoals}
                />
              </CreatorMessageWrapper>
            )}
          </CreatorMessageWrapperAndStretchGoalsWrapper>
          {isCreatorMessageEligible(
            isCreatorBranded,
            creatorMessage,
            hasLiveProducts,
            isPreview,
            previewCreatorMessage,
          ) && (
            <MobileCreatorMessageWrapper>
              <CreatorMessage
                collectionTitle={creatorCollection.title}
                creatorMessage={isPreview ? previewCreatorMessage || '' : creatorMessage || ''}
                creatorAvatarURL={isPreview ? previewCreatorAvatarURL || '' : creatorAvatarURL || ''}
                creatorLogoURL={isPreview ? previewCreatorLogoURL || '' : creatorLogoURL || ''}
                shouldDisplayStretchGoals={shouldShowStretchGoals || shouldDisplayStretchGoals}
              />
            </MobileCreatorMessageWrapper>
          )}
        </CompleteCollectionAndMessageAndStretchGoalsWrapper>

        {isCreatorBranded && !isPreview && hasLiveProducts && mediaEmbed && (
          <MediaEmbed
            dangerouslySetInnerHTML={{
              __html: mediaEmbed,
            }}
          />
        )}
        {isPreview && previewMediaEmbed && (
          <MediaEmbed
            dangerouslySetInnerHTML={{
              __html: previewMediaEmbed,
            }}
          />
        )}
        <CreatorProductsWrapper isCreatorBranded={!!isCreatorBranded}>
          {campaignStatuses.map(({ tag, status }, index) => {
            if (filteredProducts(tag).length === 0) {
              return <></>;
            }
            return (
              <>
                <CreatorProductsDisplayWrapper key={status}>
                  <CreatorProducts
                    key={status}
                    products={filteredProducts(tag)}
                    campaignStatus={status}
                    creatorName={creatorCollection.title}
                    isCreatorBrandedPage={isCreatorBranded ?? false}
                    isPreview={isPreview}
                  />
                </CreatorProductsDisplayWrapper>
              </>
            );
          })}
        </CreatorProductsWrapper>
        <SubscribeWrapper>
          <SubscribeContainer isCreatorBranded={isCreatorBranded || isPreview}>
            <SubscribeText>
              Never miss another {creatorCollection.title && `${creatorCollection.title}`} product launch again! 🚀
            </SubscribeText>
            <SubscribeButton
              isCreatorBranded={isCreatorBranded || isPreview}
              onClick={() => {
                setIsModalOpen(true);
                trackSubscribeButtonClicked(undefined, state.user, 'Subscribe For Creator Updates');
              }}
            >
              Subscribe For Email Updates
            </SubscribeButton>
            {socialLinksButtons.length > 0 && <SubscribeText>Follow {creatorCollection.title}:</SubscribeText>}
            {socialLinksButtons.length > 0 && <CreatorSocialsWrapper>{socialLinksButtons}</CreatorSocialsWrapper>}
          </SubscribeContainer>
        </SubscribeWrapper>
        <LazySubscribeModal
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          tags={`subscribed-creator, ${creatorCollection.title}`}
          description={`Don't miss out on future product launches from ${creatorCollection.title}!`}
          title="Subscribe for Creator Updates"
        />
      </ShopPageWrapper>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<CreatorPageProps> = async (ctx) => {
  const { params } = ctx;
  const collectionHandle = params ? (params.handle as string) : '';
  const numberOfCollectionProducts = 50;
  let productDropCollectionMetafields: ProductDropCollectionMetafields | null = null;
  let latestProductDropCollection: Shopify.Collection | null = null;
  let shouldEnableStretchGoals = false;

  const creatorCollection = await getCollectionByHandle(collectionHandle, undefined, 1, numberOfCollectionProducts);
  if (collectionHandle === '' || creatorCollection === null) {
    const redirectResponse = await getTargetURLFromPath(`/collections/${collectionHandle}`)
      .then((response) => response.data)
      .catch(() => null);

    const targetUrl = redirectResponse?.targetUrl?.split('/').pop();
    if (targetUrl) {
      return {
        redirect: {
          destination: targetUrl,
          permanent: true,
        },
      };
    }

    return {
      notFound: true,
    };
  }

  const { metafields } = creatorCollection;

  const creatorCollectionMetafields = parseCreatorCollectionMetafields(metafields);

  const { productDropCollections } = creatorCollectionMetafields;

  if (productDropCollections) {
    ({
      productDropCollectionMetafields,
      latestProductDropCollection,
      shouldEnableStretchGoals,
    } = await fetchLatestProductDropDetails(productDropCollections, numberOfCollectionProducts));
  }

  const propsForPageLoad = await getPropsForPageLoad(ctx);

  return {
    props: {
      creatorCollection,
      collectionHandle,
      creatorCollectionMetafields,
      latestProductDropCollection,
      productDropCollectionMetafields,
      shouldEnableStretchGoals,
      ...propsForPageLoad,
    },
  };
};

export default CreatorPage;

import React from 'react';
import { Link as StyledLink, utils } from '@makeship/core';
import styled from 'styled-components';
import { scroller } from 'react-scroll';
import config from '../../../../config.json';
import { ProductStage } from '../../../types/common';
import { getCampaignStageTag, getProductIDFromShopifyGID, getStage } from '../../../utils/product';
import { S2, S1, P2 } from '../../Typography';
import { getPDPTrackingLocation, handleSupportLinkTracking } from '../../../utils/analytics';

const { routes } = config;

const ShipDateContainer = styled.div<{ isInInfoDrawer?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 24px;

  ${({ isInInfoDrawer }) => isInInfoDrawer && `margin: 0px;`}
`;

const ShipDateContainerPast = styled(ShipDateContainer)`
  align-items: start;
`;

const ShipDateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShipDateText = styled(S2)`
  margin-left: 8px;
`;

const ShipDateTextInDrawer = styled(P2)<{ isInInfoDrawer: boolean }>`
  margin-left: 8px;

  ${({ isInInfoDrawer }) => isInInfoDrawer && `font-weight: 700;`}
`;

const ShipDateTextPast = styled(S1)`
  margin-left: 8px;
`;

const HelpCenterLink = styled(StyledLink.Primary)``;

export const getSubscribeLabel = (stage: ProductStage, isSoldOut: boolean, isEvergreen: boolean): string => {
  switch (stage) {
    case ProductStage.Failed:
      return `Enter your email to receive updates about future ${isEvergreen ? 'products' : 'campaigns'}.`;
    case ProductStage.Live:
      if (isSoldOut) {
        return `Enter your email to get notified ${
          isEvergreen ? 'when this product' : 'if this product ever'
        } comes back... 👀`;
      }
      return `Enter your email to receive updates about this ${isEvergreen ? 'product' : 'campaign'}!`;
    default:
      return `Enter your email to receive updates about this ${isEvergreen ? 'product' : 'campaign'}!`;
  }
};

export const createEvergreenTags = (product: Shopify.Product): string | undefined =>
  product.id ? `${getProductIDFromShopifyGID(product.id)}-evergreen-waitlist` : undefined;

export const createTags = (
  product: Shopify.Product,
  creatorCollectionHandle: string | null,
  totalInventory: number,
  moq: number,
  isSoldOut: boolean,
): string | undefined => {
  const stage = getStage(product.tags);

  // We will almost always return this
  if (product.id && creatorCollectionHandle) {
    return `${product.title}, ${creatorCollectionHandle}, ${getCampaignStageTag(
      stage,
      totalInventory,
      moq,
      isSoldOut,
    )}, subscribed-product`;
  }
  // We need this to handle empty tags
  if (!product.id && !creatorCollectionHandle) {
    return undefined;
  }
  return creatorCollectionHandle
    ? `${creatorCollectionHandle}, subscribed-product`
    : `${product.title}, ${getCampaignStageTag(stage, totalInventory, moq, isSoldOut)}, subscribed-product`;
};

export const renderShippingDate = (
  delivery: string,
  stage: ProductStage,
  isInInfoDrawer = false,
  product: Shopify.Product,
  isCollective?: boolean,
): JSX.Element => {
  if (stage !== ProductStage.Past && isInInfoDrawer) {
    return (
      <ShipDateContainer isInInfoDrawer={isInInfoDrawer}>
        <ShipDateWrapper>
          📦
          <ShipDateTextInDrawer isInInfoDrawer={isInInfoDrawer}>Ships {utils.getDate(delivery)}</ShipDateTextInDrawer>
        </ShipDateWrapper>
      </ShipDateContainer>
    );
  }

  if (isCollective) {
    return (
      <ShipDateContainer isInInfoDrawer={isInInfoDrawer}>
        <ShipDateWrapper>
          📦
          <ShipDateText>Estimated to Ship: {utils.getDateNoDay(delivery)}</ShipDateText>
        </ShipDateWrapper>
      </ShipDateContainer>
    );
  }

  return stage === ProductStage.Past ? (
    <ShipDateContainerPast>
      <ShipDateWrapper>
        📦
        <ShipDateTextPast>Ships {utils.getDate(delivery)}</ShipDateTextPast>
      </ShipDateWrapper>
      {renderLearnMoreShippingLink(product)}
    </ShipDateContainerPast>
  ) : (
    <ShipDateContainer isInInfoDrawer={isInInfoDrawer}>
      <ShipDateWrapper>
        📦
        <ShipDateText>Ships {utils.getDate(delivery)}</ShipDateText>
      </ShipDateWrapper>
    </ShipDateContainer>
  );
};

export const renderLearnMoreShippingLink = (
  product: Shopify.Product,
  alternateEventTrackingLocation?: string,
): JSX.Element => (
  <HelpCenterLink
    target="_blank"
    href={`${routes.helpCenter}/articles/7989946643085-When-will-my-order-ship-`}
    rel="noopener noreferrer"
    data-testid="help-center"
    onClick={() => {
      const eventTrackingLocation = alternateEventTrackingLocation || getPDPTrackingLocation(product);
      handleSupportLinkTracking('shipping_info', eventTrackingLocation, product);
    }}
  >
    Learn more about our shipping timelines
  </HelpCenterLink>
);

export const renderSupportLink = (product: Shopify.Product): JSX.Element => (
  <HelpCenterLink
    target="_blank"
    href="https://support.makeship.com/hc/en-us?_gl=1*ofbirr*_gcl_aw*R0NMLjE3MTIwNjU4NjkuQ2owS0NRancyYTZ3QmhDVkFSSXNBQlBlSDF1N3pDM2VRZWRJbVhlcGc5VG1fWWthSDNheUZ5YU5iRVNfOXNfeV9zOW95cUs2VFkybHBFMGFBaWdyRUFMd193Y0I.*_gcl_au*NDkwMDQxNDY4LjE3MDY1Mzg4MzM.&_ga=2.66871598.1246619359.1713190713-497336045.1711559450&_gac=1.217585892.1712065870.Cj0KCQjw2a6wBhCVARIsABPeH1u7zC3eQedImXepg9Tm_YkaH3ayFyaNbES_9s_y_s9oyqK6TY2lpE0aAigrEALw_wcB"
    rel="noopener noreferrer"
    data-testid="help-center"
    onClick={() => {
      handleSupportLinkTracking('support_center', getPDPTrackingLocation(product), product);
    }}
  >
    Learn more here
  </HelpCenterLink>
);

export const getSubscribeButtonText = (stage: ProductStage, isEvergreen?: boolean) => {
  if (isEvergreen && stage !== ProductStage.ComingSoon) {
    return `Notify Me When It's Back`;
  }

  switch (stage) {
    case ProductStage.ComingSoon:
    case ProductStage.Past:
      return 'Subscribe for Creator Updates';
    default:
      return 'Bring it Back!';
  }
};

export const getSubscribeTitle = (stage: ProductStage, isEvergreen?: boolean) => {
  if (isEvergreen) {
    return `Notify Me When It's Back`;
  }

  switch (stage) {
    case ProductStage.ComingSoon:
      return 'Subscribe to Updates!';
    case ProductStage.Past:
      return 'Subscribe for Creator Updates';
    default:
      return 'Bring it Back!';
  }
};

export const getSubscribeDescription = (
  stage: ProductStage,
  isEvergreen?: boolean,
  productTitle?: string,
  creatorName?: string,
) => {
  if (isEvergreen && productTitle) {
    return `Don't miss out on the next batch of the ${productTitle}!`;
  }

  if (stage === ProductStage.Past && creatorName) {
    return `Don't miss out on future product launches from ${creatorName}`;
  }

  switch (stage) {
    case ProductStage.ComingSoon:
      return 'Enter your email to get notified when this product goes live!';
    default:
      return 'Enter your email to get notified if this product ever comes back... 👀';
  }
};

export const scrollToSizeError = () => {
  scroller.scrollTo('size-error', {
    duration: 800,
    smooth: 'easeInOutQuad',
    offset: -200,
  });
};

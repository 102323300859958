import React from 'react';
import styled from 'styled-components';
import ReactCountdown, { CountdownRenderProps } from 'react-countdown';
import { Caption, H5 } from '../Typography';

const CountdownTimerWrapper = styled.div<{ textColour?: string }>`
  display: flex;
  justify-content: space-between;
  ${H5}, ${Caption} {
    color: ${({ textColour, theme }) => textColour || theme.colors.neutral7};
  }
  ${Caption} {
    line-height: 0.5;
  }
  padding-bottom: 8px;
`;

const TimeElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
  width: 25%;
`;

type CountdownTimerProps = {
  date: string;
  textColour?: string;
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({ date, textColour }: CountdownTimerProps) => {
  const renderer = ({ formatted: { days, hours, minutes, seconds } }: CountdownRenderProps) => (
    <CountdownTimerWrapper textColour={textColour}>
      <TimeElement>
        <H5>{days}</H5>
        <Caption>days</Caption>
      </TimeElement>
      <H5>:</H5>
      <TimeElement>
        <H5>{hours}</H5>
        <Caption>hours</Caption>
      </TimeElement>
      <H5>:</H5>
      <TimeElement>
        <H5>{minutes}</H5>
        <Caption>minutes</Caption>
      </TimeElement>
      <H5>:</H5>
      <TimeElement>
        <H5>{seconds}</H5>
        <Caption>seconds</Caption>
      </TimeElement>
    </CountdownTimerWrapper>
  );

  return <ReactCountdown date={new Date(date)} renderer={renderer} />;
};

export default CountdownTimer;

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import posthog from 'posthog-js';
import { useStore } from '../../../../store';
import { S3, S2, Caption } from '../../../Typography';
import { formatNumberStringWithComma } from '../../../../utils/accounting';
import { isUserLoggedIn } from '../../../../utils/analytics';
import { StretchGoalsTheme } from '../../../../types/common';
import { ReactComponent as Info } from '../../../../../public/assets/icons/info-icon-2.svg';

type StretchGoalsProgressBarProps = {
  latestProductDropCollection: Shopify.Collection;
  productDropCollectionMetafields: Required<ProductDropCollectionMetafields>;
  totalUnitsSold: number;
  productDropMOQ: number;
};

const getProgressBarBackgroundGradient = (theme: any, stretchGoalsTheme: string) => {
  switch (stretchGoalsTheme) {
    case StretchGoalsTheme.Silver:
      return `${utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)}`;
    case StretchGoalsTheme.Gold:
    case StretchGoalsTheme.Titanium:
    default:
      return `${utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)}`;
  }
};

const getProgressBackgroundGradient = (stretchGoalsTheme: string) => {
  switch (stretchGoalsTheme) {
    case StretchGoalsTheme.Titanium:
      return 'linear-gradient(90deg, #578DC7 0%, #80B1DD 22.5%, #ACD0EB 43.5%, #D3E3F2 64%, #84B1DF 82%, #ADCFEB 100%)';
    case StretchGoalsTheme.Silver:
      return 'linear-gradient(90deg, #828488 0%, #DDE0E8 22.5%, #CDCDCF 43.5%, #A3A4AA 64%, #F1F0F5 82%, #E4E4E4 100%)';
    case StretchGoalsTheme.Gold:
    default:
      return 'linear-gradient(90deg, #9A7F36 0%, #9C7D37 22.5%, #BC9D41 43.5%, #FDFBBC 64%, #DFC358 82%, #9D803C 100%)';
  }
};

const ProgressContainer = styled.div`
  position: relative;
  margin-right: 6px;
`;

const ProgressBar = styled.div<{ stretchGoalsTheme: string }>`
  position: relative;
  width: 100%;
  height: 10px;
  background: ${({ theme, stretchGoalsTheme }) => getProgressBarBackgroundGradient(theme, stretchGoalsTheme)};
  border-radius: 45px;
  margin-top: 8px;
`;

const Progress = styled.div<{ width: number; stretchGoalsTheme: string }>`
  height: 100%;
  width: ${({ width }) => `${width}%`};
  background: ${({ stretchGoalsTheme }) => getProgressBackgroundGradient(stretchGoalsTheme)};
  position: relative;
  transition: width 0.3s ease;
  border-radius: 8px;
`;

const MilestoneIcon = styled.div<{ achieved: boolean }>`
  position: absolute;
  top: -26px;
  width: 16px;
  height: 36px;
  background: ${({ achieved }) =>
    achieved
      ? "url('/assets/stretch-goals/stretch-goals-checkmark-icon.png') no-repeat center center"
      : "url('/assets/stretch-goals/stretch-goals-star-icon.png') no-repeat center center"};
  background-size: contain;
  transform: translateX(-50%);
`;

const TooltipMilestone = styled.div<{ visible: boolean; toRight: boolean }>`
  position: absolute;
  bottom: 38px;
  ${({ toRight }) => (toRight ? 'left:2px' : 'right:2px')};
  background-color: ${({ theme }) => theme.colors.neutral6};
  color: white;
  padding: 14px 12px;
  border-radius: ${({ toRight }) => (toRight ? '12px 12px 12px 0px' : '12px 12px 0px 12px')};
  font-size: 12px;
  white-space: nowrap;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const UnitsRemaining = styled(S2)`
  font-size: 14px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    font-size: 16px;
  }
`;

const TotalSold = styled(Caption)``;

const InfoIconWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.neutral6};
  left: 6px;
  bottom: 20px;
  width: 234px;
  padding: 8px 16px;
  border-radius: 12px 12px 12px 0;
  cursor: pointer;
  z-index: 1;

  @media screen and (max-width: 460px) {
    left: -70px;
  }
`;

const TooltipSubtitle = styled(S3)`
  color: ${({ theme }) => theme.colors.neutral1};
`;

const TooltipText = styled(Caption)`
  color: ${({ theme }) => theme.colors.neutral1};
  & > span {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const InfoIcon = styled(Info)`
  margin-left: 8px;
  vertical-align: text-top;
`;

const StretchGoalsProgressBar: React.FC<StretchGoalsProgressBarProps> = ({
  latestProductDropCollection,
  productDropCollectionMetafields,
  totalUnitsSold,
  productDropMOQ,
}: StretchGoalsProgressBarProps) => {
  const { state } = useStore();

  const [isTooltipClicked, setIsTooltipClicked] = useState(false);
  const [hoveredMilestone, setHoveredMilestone] = useState<number | null>(null);

  const numberOfProducts = latestProductDropCollection.products.edges.length;

  const { stretchGoalsData, stretchGoalsTheme } = productDropCollectionMetafields;
  let { milestones, goals } = stretchGoalsData;
  milestones = [productDropMOQ, ...milestones];
  goals = [
    {
      amount: productDropMOQ,
      title: `${numberOfProducts === 1 ? 'Product' : 'Products'} Funded! 🚀`,
    },
    ...goals,
  ];

  const maxMilestone = Math.max(...milestones);
  const progressPercentage = Math.min((totalUnitsSold / maxMilestone) * 100, 100);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e: MouseEvent) => {
    if (!!ref.current && !ref.current.contains(e.target as Node)) {
      setIsTooltipClicked(false);
    }
  };

  const handleTooltipClick = () => {
    posthog.capture('stretch_goals_reward_list_tooltip_clicked', {
      tooltipClicked: !isTooltipClicked,
      is_logged_in: isUserLoggedIn(state.user),
      product_drop_collection_id: latestProductDropCollection.id,
    });
    setIsTooltipClicked(!isTooltipClicked);
  };

  const tooltip = (
    <Tooltip>
      <TooltipSubtitle>How do stretch goals work?</TooltipSubtitle>
      <TooltipText>
        Each live product purchased will contribute to the goal/free gift. Once the goal is reached, every order
        (regardless of when it was purchased) will include the free gift. One gift per unit purchased.
      </TooltipText>
    </Tooltip>
  );

  return (
    <ProgressContainer>
      <ProgressBar stretchGoalsTheme={stretchGoalsTheme}>
        <Progress width={progressPercentage} stretchGoalsTheme={stretchGoalsTheme} />
        {goals.map((goal) => {
          const milestone = goal.amount;
          const achieved = totalUnitsSold >= milestone;
          const position = (milestone / maxMilestone) * 100;
          return (
            <MilestoneIcon
              key={milestone}
              achieved={achieved}
              style={{ left: `${position}%` }}
              onMouseEnter={() => setHoveredMilestone(milestone)}
              onMouseLeave={() => setHoveredMilestone(null)}
              onClick={() => setHoveredMilestone(milestone)}
            >
              <TooltipMilestone visible={hoveredMilestone === milestone} toRight={milestone === productDropMOQ}>
                {milestone !== productDropMOQ ? (
                  <>
                    {achieved ? (
                      <TooltipSubtitle>Free gift unlocked!</TooltipSubtitle>
                    ) : (
                      <>
                        <TooltipSubtitle>Free gift unlocked at:</TooltipSubtitle>
                        <TooltipText>{formatNumberStringWithComma(milestone)} Total Sold: </TooltipText>
                        <TooltipText>{goal.title}</TooltipText>
                      </>
                    )}
                  </>
                ) : (
                  <TooltipSubtitle>{goal.title}</TooltipSubtitle>
                )}
              </TooltipMilestone>
            </MilestoneIcon>
          );
        })}
      </ProgressBar>
      <InfoContainer>
        {totalUnitsSold >= maxMilestone ? (
          <UnitsRemaining>Free gift unlocked!</UnitsRemaining>
        ) : (
          <UnitsRemaining>
            {formatNumberStringWithComma(maxMilestone - totalUnitsSold)} units to your free gift!
          </UnitsRemaining>
        )}
        <InfoIconWrapper
          ref={ref}
          onClick={(event) => {
            event.stopPropagation();
            handleTooltipClick();
          }}
        >
          <InfoIcon />
          {isTooltipClicked && tooltip}
        </InfoIconWrapper>
        <TotalSold>{formatNumberStringWithComma(totalUnitsSold)} sold</TotalSold>
      </InfoContainer>
    </ProgressContainer>
  );
};

export default StretchGoalsProgressBar;

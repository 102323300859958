import { useState, useEffect } from 'react';
import { getProductSalesQuantityForGT } from '../api/storefront/product';

export const useFetchSalesQuantityForGT = (isGTReward: boolean, currentProductDropSalesQuantity: number) => {
  const [gtSalesQuantity, setGtSalesQuantity] = useState<ProductSalesQuantity | null>({
    quantity: currentProductDropSalesQuantity,
  });

  useEffect(() => {
    if (!isGTReward) {
      return;
    }

    const fetchData = async () => {
      try {
        const gtSalesQuantity = await getProductSalesQuantityForGT()
          .then((response) => response.data)
          .catch(() => null);

        setGtSalesQuantity(gtSalesQuantity);
      } catch (err: any) {
        setGtSalesQuantity(null);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 10_000);

    return () => clearInterval(intervalId);
  }, []);

  return { gtSalesQuantity };
};

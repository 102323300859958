import React, { useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import styled, { ThemeContext } from 'styled-components';
import { useStore } from '../../../../store';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { parseCreatorCollectionMetafields } from '../../../../utils/collections';
import PreviewWarning from '../../../Preview/PreviewWarning';

const CreatorHeaderWrapper = styled.div`
  position: relative;
`;

const CreatorHeaderBackgroundWrapper = styled.div<{ isMobile: boolean }>`
  height: 258px;
  width: 100%;
  position: relative;
  padding-top: 36px;
  background: ${({ theme }) => theme.colors.makeshipBlack};
`;

const CreatorHeaderBackground = styled(Image)``;

const PreviewWarningWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
`;

type CustomCreatorCollectionHeaderProps = {
  creatorCollection: Shopify.Collection;
  isPreview?: boolean;
};

const CustomCreatorCollectionHeader: React.FC<CustomCreatorCollectionHeaderProps> = ({
  creatorCollection,
  isPreview,
}: CustomCreatorCollectionHeaderProps) => {
  const { state } = useStore();
  const size = useWindowSize();
  const theme = useContext(ThemeContext);

  const [isMobileViewport, setIsMobileViewport] = useState<boolean>(false);

  const {
    socialLinks,
    headerBackgroundURL,
    creatorLogoURL,
    previewSocialLinks,
    previewHeaderBackgroundURL,
    previewCreatorLogoURL,
  } = parseCreatorCollectionMetafields(creatorCollection?.metafields);

  const socialLinksObject = JSON.parse(isPreview ? previewSocialLinks || '{}' : socialLinks || '{}');

  useEffect(() => {
    if (!size.width) {
      return;
    }
    // eslint-disable-next-line react/destructuring-assignment
    const isMobileViewport = size.width < theme.breakpoints.desktop;
    setIsMobileViewport(isMobileViewport);
  }, [size]);

  const linksToIconsMap = {
    twitter: '/assets/icons/twitter-white-icon.svg',
    instagram: '/assets/icons/instagram-white-icon.svg',
    facebook: '/assets/icons/facebook-white-icon.svg',
    youtube: '/assets/icons/youtube-icon.svg',
    tiktok: '/assets/icons/tiktok-white-icon.svg',
    discord: '/assets/icons/discord-icon.svg',
    twitch: '/assets/icons/twitch-icon.svg',
    bluesky: '/assets/icons/bluesky-icon.svg',
    other: '/assets/icons/other-icon.svg',
  };

  return (
    <CreatorHeaderWrapper>
      <CreatorHeaderBackgroundWrapper isMobile={isMobileViewport}>
        <CreatorHeaderBackground
          src={isPreview ? previewHeaderBackgroundURL : headerBackgroundURL}
          layout="fill"
          objectFit="cover"
          quality={90}
          alt={`${creatorCollection.title} header background`}
          unoptimized
          priority
        />
        {isPreview && (
          <PreviewWarningWrapper>
            <PreviewWarning />
          </PreviewWarningWrapper>
        )}
      </CreatorHeaderBackgroundWrapper>
    </CreatorHeaderWrapper>
  );
};

export default CustomCreatorCollectionHeader;

import React, { useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import posthog from 'posthog-js';
import { utils } from '@makeship/core';
import { S3, Caption, Overline } from '../../../Typography';
import { StretchGoalsTheme } from '../../../../types/common';

type StretchGoalsRewardListProps = {
  productDropCollectionMetafields: Required<ProductDropCollectionMetafields>;
  latestProductDropCollection: Shopify.Collection;
  totalUnitsSold: number;
  productDropMOQ: number;
};

const getBackgroundGradient = (stretchGoalsTheme: string) => {
  switch (stretchGoalsTheme) {
    case StretchGoalsTheme.Titanium:
      return 'linear-gradient(93deg, rgba(87, 141, 199, 0.60) 0.09%, rgba(128, 177, 221, 0.60) 22.07%, rgba(172, 208, 235, 0.60) 44.05%, rgba(211, 227, 242, 0.60) 64.03%, rgba(132, 177, 223, 0.60) 82.02%, rgba(173, 207, 235, 0.60) 100%)';
    case StretchGoalsTheme.Silver:
      return 'linear-gradient(93deg, rgba(130, 132, 136, 0.60) 0.09%, rgba(221, 224, 232, 0.60) 22.07%, rgba(205, 205, 207, 0.60) 44.05%, rgba(163, 164, 170, 0.60) 64.03%, rgba(241, 240, 245, 0.60) 82.02%, rgba(228, 228, 228, 0.60) 100%)';
    case StretchGoalsTheme.Gold:
    default:
      return 'linear-gradient(93deg, rgba(154, 127, 54, 0.6) 0.09%, rgba(156, 125, 55, 0.6) 22.07%, rgba(188, 157, 65, 0.6) 44.05%, rgba(253, 251, 188, 0.6) 64.03%, rgba(223, 195, 88, 0.6) 82.02%, rgba(157, 128, 60, 0.6) 100%)';
  }
};

const Container = styled.div<{ stretchGoalsTheme: string }>`
  background: ${({ stretchGoalsTheme }) => getBackgroundGradient(stretchGoalsTheme)};
  border: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  border-radius: 8px;
  padding: 12px;
  max-width: 430px;
  margin: 0 auto;
`;

const Title = styled(S3)`
  margin: 10px 0;
`;

const GoalList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const GoalItem = styled.li<{ unlocked: boolean }>`
  display: flex;
  align-items: start;
  margin-bottom: 12px;
`;

const GoalEmoji = styled.span`
  margin-right: 8px;
  padding-top: 2px;
  font-size: 18px;
`;

const GoalText = styled.div`
  display: flex;
  gap: 6px;
`;

const GoalTitle = styled(S3)`
  white-space: nowrap;
`;

const GoalImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const GoalImageWrapper = styled.div<{ unlocked: boolean }>`
  display: flex;
  position: relative;
  height: 92px;
  width: 92px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  border: 2px solid
    ${({ theme, unlocked }) =>
      unlocked ? theme.colors.neutral7 : utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  background: ${({ unlocked }) => (unlocked ? 'none' : 'rgba(0, 0, 0, 0.2)')};
  transition: background 0.3s ease, transform 0.3s ease;
  cursor: pointer;
`;

const GoalImage = styled(Image)<{ unlocked: boolean }>`
  filter: ${({ unlocked }) => (unlocked ? 'none' : 'brightness(0.7)')};
  opacity: 1;
  transition: filter 0.3s ease;
`;

const StatusIcon = styled.div`
  position: absolute;
  top: 0;
  left: -2px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 1;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  border-radius: 8px;
  background: white;
`;

const StyledOverline = styled(Overline)`
  margin-top: 8px;
  justify-self: center;
`;

const StretchGoalsRewardList: React.FC<StretchGoalsRewardListProps> = ({
  productDropCollectionMetafields,
  latestProductDropCollection,
  totalUnitsSold,
  productDropMOQ,
}: StretchGoalsRewardListProps) => {
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  const numberOfProducts = latestProductDropCollection.products.edges.length;

  const { stretchGoalsData, stretchGoalsImagesURLs, stretchGoalsTheme } = productDropCollectionMetafields;

  const { title } = stretchGoalsData;
  let { goals } = stretchGoalsData;

  goals = [
    {
      amount: productDropMOQ,
      title: `${numberOfProducts === 1 ? 'Product' : 'Products'} Funded! 🚀`,
    },
    ...goals,
  ];

  const handleImageClick = (image: string) => {
    posthog.capture('stretch_goals_reward_list_image_clicked', {
      image,
    });
    setEnlargedImage(image);
  };

  const closeModal = () => {
    setEnlargedImage(null);
  };

  return (
    <Container stretchGoalsTheme={stretchGoalsTheme}>
      <Title>{title}</Title>
      <GoalList>
        {goals &&
          goals.map((goal, index) => {
            const { amount, title } = goal;
            const unlocked = totalUnitsSold >= amount;
            const isProductsFundedMilstone = index === 0;

            return (
              <GoalItem key={amount} unlocked={unlocked}>
                <GoalEmoji>{unlocked ? '✅' : '🔒'}</GoalEmoji>
                <GoalText>
                  <GoalTitle>
                    {isProductsFundedMilstone ? title : `${amount.toLocaleString()}${unlocked ? '+' : ''} Sold:`}
                  </GoalTitle>
                  {!isProductsFundedMilstone && <Caption>{title}</Caption>}
                </GoalText>
              </GoalItem>
            );
          })}
        <GoalImagesContainer>
          {goals &&
            goals.map((goal, index) => {
              const { amount, title } = goal;
              if (amount === productDropMOQ) return null;
              const unlocked = totalUnitsSold >= amount;
              return (
                <GoalImageWrapper
                  unlocked={unlocked}
                  onClick={() => handleImageClick(stretchGoalsImagesURLs[index - 1])}
                >
                  <GoalImage
                    key={amount}
                    src={stretchGoalsImagesURLs[index - 1]}
                    width={74}
                    height={74}
                    alt={title}
                    unlocked={unlocked}
                  />
                  <StatusIcon>{unlocked ? '✅' : '🔒'}</StatusIcon>
                </GoalImageWrapper>
              );
            })}
        </GoalImagesContainer>
        <StyledOverline>Tap image to view gift!</StyledOverline>
      </GoalList>
      {enlargedImage && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
          >
            <Image src={enlargedImage} alt="Enlarged Goal" layout="intrinsic" width={324} height={324} />
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default StretchGoalsRewardList;

import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { utils } from '@makeship/core';
import { Overline, Caption, S3 } from '../../../Typography';

const CreatorMessageContainer = styled.div<{ shouldDisplayStretchGoals: boolean }>`
  display: flex;
  gap: 14px;
  margin: 0 auto 20px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-top: ${({ shouldDisplayStretchGoals }) => (shouldDisplayStretchGoals ? `30px` : `0`)};
    margin: 0;
    margin-bottom: 30px;
  }
`;

const CreatorMessageTextWrapper = styled.div`
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 90)};
  border-radius: 0px 12px 12px 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border: 1px solid var(--opacity-15, rgba(20, 20, 20, 0.15));
  height: fit-content;
`;

const CreatorMessageText = styled(Caption)``;

const CreatorAvatarWrapper = styled.div`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--opacity-15, rgba(20, 20, 20, 0.15));
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.headerBreakpoint + 1}px) {
    min-width: 106px;
    width: 106px;
    height: 106px;
  }
`;

const CreatorAvatar = styled(Image)`
  border-radius: 8px;
`;

const Pill = styled.div`
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
  border-radius: 46px;
  padding: 6px;
  width: fit-content;
  height: 21px;
  display: flex;
  align-items: center;
`;

const PillText = styled(Overline)`
  color: ${({ theme }) => theme.colors.neutral1};
`;

const CreatorTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

type CreatorMessageProps = {
  collectionTitle: string;
  creatorMessage: string;
  creatorAvatarURL: string;
  creatorLogoURL: string;
  shouldDisplayStretchGoals: boolean;
};

const CreatorMessage: React.FC<CreatorMessageProps> = ({
  collectionTitle,
  creatorMessage,
  creatorAvatarURL,
  creatorLogoURL,
  shouldDisplayStretchGoals,
}: CreatorMessageProps) => (
  <CreatorMessageContainer shouldDisplayStretchGoals={shouldDisplayStretchGoals}>
    {(creatorAvatarURL || creatorLogoURL) && (
      <CreatorAvatarWrapper>
        <CreatorAvatar
          width={106}
          height={106}
          src={creatorAvatarURL || creatorLogoURL}
          objectFit="cover"
          alt="creator avatar"
        />
      </CreatorAvatarWrapper>
    )}
    <CreatorMessageTextWrapper>
      <CreatorTitleWrapper>
        <S3>{collectionTitle}</S3>
        <Pill>
          <PillText>Creator</PillText>
        </Pill>
      </CreatorTitleWrapper>
      <CreatorMessageText>{creatorMessage}</CreatorMessageText>
    </CreatorMessageTextWrapper>
  </CreatorMessageContainer>
);

export default CreatorMessage;

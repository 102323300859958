import React, { useState } from 'react';
import { Element as ScrollElement } from 'react-scroll';
import styled from 'styled-components';
import { Link as StyledLink } from '@makeship/core';

import posthog from 'posthog-js';
import { P2, S2, S2Styles } from '../Typography';
import SingleSizeOption from './SingleSizeOption';
import SizeGuideModal from '../Modal/SizeGuideModal';
import { findByErrorType } from '../../utils/errors';
import { ErrorType, VariantSelectionError } from '../../types/errors';
import { getProductIDFromShopifyGID, getProductType, getStage } from '../../utils/product';
import { isUserLoggedIn } from '../../utils/analytics';
import { useStore } from '../../store';

const SizeSelectorWrapper = styled.div<{ inCompleteTheCollection?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  margin-top: 42px;
  margin-bottom: 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin-bottom: 20px;
  }

  ${({ inCompleteTheCollection }) =>
    inCompleteTheCollection &&
    `margin-top: 8px;
    margin-bottom: 16px;
  `}
`;

const SizesTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SizesTextSpacer = styled.div`
  width: 4px;
`;

const BottomLink = styled(StyledLink.Primary)<{ isCreatorBranded?: boolean }>`
  ${S2Styles}
  color: ${({ theme, isCreatorBranded }) =>
    isCreatorBranded ? theme.colors.neutral7 : theme.colors.primary} !important;
  display: block;
  cursor: pointer;
`;

const ErrorText = styled(P2)`
  color: ${({ theme }) => theme.colors.error};
  margin-top: 8px;
  margin-bottom: 20px;
`;

const OptionsWrapper = styled.div<{ error?: boolean }>`
  flex-wrap: wrap;
  display: inline-flex;

  justify-content: flex-start;
  column-gap: 14px;
  margin-top: 20px;
  row-gap: 8px;

  ${({ theme, error }) =>
    error &&
    `
    border: 1px solid ${theme.colors.error} !important; 
    padding: 2px;
    border-radius: 8px;
    margin-top: 0;
  `}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    column-gap: 4px;
  }

  @media (max-width: 360px) {
    margin-left: -12px;
  }
`;

type SizeSelectorProps = {
  errors: VariantSelectionError[];
  setErrors: (error: VariantSelectionError[]) => void;
  variants: Shopify.ProductVariantEdge[];
  setSelectedVariants: (variant: Shopify.ProductVariant[]) => void;
  setSelectedVariant?: (variant: Shopify.ProductVariant) => void;
  product?: Shopify.Product;
  inCompleteTheCollection?: boolean;
  selectedVariants?: Shopify.ProductVariant[];
  handleSizeSelectionProductSelection?: (variant: Shopify.Product) => void;
  isCreatorBranded?: boolean;
};

const SizeSelector: React.FC<SizeSelectorProps> = ({
  errors,
  setErrors,
  variants,
  setSelectedVariants,
  setSelectedVariant,
  product,
  inCompleteTheCollection,
  selectedVariants,
  handleSizeSelectionProductSelection,
  isCreatorBranded,
}: SizeSelectorProps) => {
  const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false);
  const { state } = useStore();

  const isVariantIDWithinArray = (id: string, variants: Shopify.ProductVariant[] | undefined): boolean => {
    if (!variants) {
      return false;
    }
    return variants.some((variant) => variant.id === id);
  };

  const handleSelectClothingVariant = (e: React.MouseEvent<HTMLDivElement>, variant: Shopify.ProductVariant) => {
    // Remove this variant from the errors and update errors
    const removedVariantFromError = errors?.filter((error) => error.productID !== variant.product.id);
    setErrors(removedVariantFromError);

    // Remove all other variants with same product id
    setSelectedVariants(
      selectedVariants?.filter((selectedVariant) => selectedVariant.product.id !== variant.product.id) || [],
    );

    // Add the variant
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setSelectedVariants((currentState) => [...currentState, variant]);

    // Used for add to cart on regular PDP for hoodies
    if (setSelectedVariant) {
      setSelectedVariant(variant);
    }

    if (handleSizeSelectionProductSelection && product) {
      handleSizeSelectionProductSelection(product);
    }
    e.stopPropagation();
  };

  const onClickSizeGuide = () => {
    setIsSizeGuideOpen(true);
    posthog.capture('size_guide_opened', {
      product_id: getProductIDFromShopifyGID(product?.id ?? ''),
      product_title: product?.title,
      product_type: product?.productType,
      is_logged_in: isUserLoggedIn(state.user),
      productStage: getStage(product?.tags ?? []),
    });
  };

  return (
    <SizeSelectorWrapper inCompleteTheCollection={inCompleteTheCollection}>
      <SizesTextWrapper>
        <S2>Select your size:</S2>
        <SizesTextSpacer />
        <BottomLink isCreatorBranded={isCreatorBranded} onClick={() => onClickSizeGuide()}>
          Size Guide
        </BottomLink>
      </SizesTextWrapper>
      <ScrollElement name="variants" />
      <ScrollElement name="size-error" />
      {findByErrorType(errors, product?.id, ErrorType.HoodieSizeMissing) && (
        <ErrorText>Please select a size!</ErrorText>
      )}
      <OptionsWrapper error={findByErrorType(errors, product?.id, ErrorType.HoodieSizeMissing)}>
        {variants.map((variant) => (
          <SingleSizeOption
            variant={variant.node}
            selected={isVariantIDWithinArray(variant.node.id, selectedVariants)}
            setSelectedVariant={(e, variant) => handleSelectClothingVariant(e, variant)}
            isCreatorBranded={isCreatorBranded}
          />
        ))}
      </OptionsWrapper>
      <SizeGuideModal
        productType={getProductType(product?.productType || '')}
        isOpen={isSizeGuideOpen}
        closeModal={() => setIsSizeGuideOpen(false)}
      />
    </SizeSelectorWrapper>
  );
};

export default SizeSelector;
